@import "./variables";
@import "./mixins";
@import "./functions";

h1 {
  font-size: $font-heading-1-size;
}

h2 {
  font-size: $font-heading-2-size;
}

h3 {
  font-size: $font-heading-3-size;
}

h4 {
  font-size: $font-heading-4-size;
}

ul {
  padding: 0;
  list-style: none;

  li {
    position: relative;
    padding: 0;
  }
}

.sa-page-container {
  position: relative;
}

.sa-container {
  padding: $padding-lg;
}

.sa-relative {
  position: relative;
}

.sa-tags {
  display: flex;
  margin-top: 5px;

  span {
    font-size: 12px;
    color: $color-white;
    padding: 2px 5px;
    background-color: $color-light-bg;
    border-radius: $border-radius-base;
    margin-right: 3px;

    &.primary {
      background-color: $color-primary;
    }

    &.success {
      background-color: $color-success;
    }

    &.error {
      background-color: $color-error;
    }

    &.warning {
      background-color: $color-warning;
    }

    &.secondary {
      background-color: $color-blue-grey;
    }

    &.info {
      background-color: $color-cyan;
    }
  }
}

.sa-dot-status {
  display: inline-block;
  height: 7.5px;
  width: 7.5px;
  border-radius: 100%;
  background-color: $color-grey;
  margin-left: $margin-xs;

  &.primary {
    background-color: $color-primary;
  }

  &.success {
    background-color: $color-success;
  }

  &.error {
    background-color: $color-error;
  }

  &.warning {
    background-color: $color-warning;
  }
}

.sa-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: $color-light-bg;
  border-radius: $border-radius-base;
  cursor: pointer;
  color: $color-inactive-text;
  font-size: $font-size-base;
  text-decoration: none;
  text-align: center;
  margin-right: 0.25em;
  @include transition($animation-duration-slow, $ease-out-quint);

  &:hover {
    color: $color-primary;
    text-decoration: $link-hover-decoration;
    background-color: rgba(0, 0, 0, 0.1);
    text-decoration: none;
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.dark {
    &.primary {
      background-color: $color-primary;
      color: $color-white;

      &:hover {
        background-color: darken($color-primary, 10%);
      }

      &:active {
        background-color: darken($color-primary, 20%);
      }

      la-icon,
      i {
        color: $color-white;
      }
    }

    &.success {
      background-color: $color-success;
      color: $color-white;

      &:hover {
        background-color: darken($color-success, 10%);
      }

      &:active {
        background-color: darken($color-success, 20%);
      }

      la-icon,
      i {
        color: $color-white;
      }
    }

    &.error {
      background-color: $color-error;
      color: $color-white;

      &:hover {
        background-color: darken($color-error, 10%);
      }

      &:active {
        background-color: darken($color-error, 20%);
      }

      la-icon,
      i {
        color: $color-white;
      }
    }

    &.warning {
      background-color: $color-warning;
      color: $color-white;

      &:hover {
        background-color: darken($color-warning, 10%);
      }

      &:active {
        background-color: darken($color-warning, 20%);
      }

      la-icon,
      i {
        color: $color-white;
      }
    }

    &.secondary {
      background-color: $color-grey;
      color: $color-white;

      &:hover {
        background-color: darken($color-grey, 10%);
      }

      &:active {
        background-color: darken($color-grey, 20%);
      }

      la-icon,
      i {
        color: $color-white;
      }
    }
  }

  &.light {
    &.primary {
      background-color: $color-primary-light;
      color: $color-primary;
      box-shadow: 0 0 0 1px lighten($color-primary, 20%) inset;

      &:hover {
        background-color: darken($color-primary-light, 3%);
      }

      &:active {
        background-color: darken($color-primary-light, 8%);
      }

      la-icon,
      i {
        color: $color-primary;
      }
    }

    &.success {
      background-color: $color-success-light;
      color: $color-success;
      box-shadow: 0 0 0 1px lighten($color-success, 20%) inset;

      &:hover {
        background-color: darken($color-success-light, 3%);
      }

      &:active {
        background-color: darken($color-success-light, 8%);
      }

      la-icon,
      i {
        color: $color-success;
      }
    }

    &.error {
      background-color: $color-error-light;
      color: $color-error;
      box-shadow: 0 0 0 1px lighten($color-error, 20%) inset;

      &:hover {
        background-color: darken($color-error-light, 3%);
      }

      &:active {
        background-color: darken($color-error-light, 8%);
      }

      la-icon,
      i {
        color: $color-error;
      }
    }

    &.warning {
      background-color: $color-warning-light;
      color: $color-warning;
      box-shadow: 0 0 0 1px lighten($color-warning, 20%) inset;

      &:hover {
        background-color: darken($color-warning-light, 3%);
      }

      &:active {
        background-color: darken($color-warning-light, 8%);
      }

      la-icon,
      i {
        color: $color-warning;
      }
    }

    &.report {
      background-color: $color-report-light;
      color: $color-deep-purple;
      box-shadow: 0 0 0 1px lighten($color-purple, 20%) inset;

      &:hover {
        background-color: darken($color-report-light, 3%);
      }

      &:active {
        background-color: darken($color-report-light, 8%);
      }

      la-icon {
        color: $color-deep-purple;
      }
    }

    &.secondary-report {
      background-color: $color-secondary-report-light;
      color: $color-purple;
      box-shadow: 0 0 0 1px lighten($color-purple, 20%) inset;

      &:hover {
        background-color: darken($color-secondary-report-light, 3%);
      }

      &:active {
        background-color: darken($color-secondary-report-light, 8%);
      }

      la-icon {
        color: $color-purple;
      }
    }

    &.secondary {
      background-color: $color-grey-light;
      color: $color-grey;
      box-shadow: 0 0 0 1px lighten($color-grey, 20%) inset;

      &:hover {
        background-color: darken($color-grey-light, 3%);
      }

      &:active {
        background-color: darken($color-grey-light, 8%);
      }

      la-icon,
      i {
        color: $color-grey;
      }
    }
  }

  la-icon,
  i {
    @include transition($animation-duration-base, $ease-out-quint);
    font-size: $font-icon-base;
  }
}

.sa-input {
  position: relative;
  margin-bottom: 15px;

  &:focus-within {
    &.with-icon {

      la-icon,
      i,
      label {
        color: $color-primary;
      }
    }
  }

  &.with-icon {
    &:focus-within {

      la-icon,
      i {
        color: $color-primary;
      }
    }

    input {
      padding-left: 50px;
    }

    la-icon,
    i {
      @include transition($animation-duration-base, $ease-out-quint);
      position: absolute;
      bottom: calc(50% - 26px);
      left: 15px;
      color: $color-inactive-text;
      font-size: $font-icon-base;
    }

    i {
      bottom: calc(50% - 21px);
    }
  }

  &.sm {
    input {
      height: 40px;
    }

    &.with-icon {
      input {
        padding-left: 40px;
      }

      la-icon,
      i {
        left: 10px;
        bottom: calc(50% - 17px);
      }

      i {
        bottom: calc(50% - 11px);
      }
    }
  }

  input {
    width: 100%;
    height: 40px;
    font-size: $font-size-base;
    outline: 0;
    background-color: $color-white;
    border-radius: $border-radius-base;
    border: 1px solid $color-border;
    padding: $padding-xs $padding-md;
    @include transition($animation-duration-base, $ease-out-quint);

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.432);

      la-icon,
      i {
        color: $color-primary;
      }
    }

    &:focus {
      background-color: $color-white;
      box-shadow: bottom-shadow(4);
    }

    &:disabled {
      background: $color-light-bg;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  label {
    display: block;
    font-size: $font-size-base;
    color: $color-text;
    @include transition($animation-duration-base, $ease-out-quint);
  }
}

.sa-form {
  margin-top: $margin-md;

  .sa-input {
    display: block;
    margin-top: $margin-xs;

    select {
      width: 100%;
      height: 35px;
      border-radius: 4px;
      background-color: $color-white;

      &:disabled {
        background-color: $color-light-bg;
      }
    }
  }
}

.sa-input-dropdown {
  position: relative;
  margin-bottom: 15px;

  label {
    display: block;
    font-size: $font-size-base;
    color: $color-text;
    @include transition($animation-duration-base, $ease-out-quint);
  }
}


.textarea-input>textarea {
  width: 100%;
  background-color: $color-white;
  padding: 5px;

  &:focus {
    box-shadow: bottom-shadow(4);
  }
}

.header-right-area {
  position: absolute;
  right: 0;
  top: 0;
  padding: $padding-xl;
  z-index: 1;
}