@import "functions";

@mixin middle() {
  position: absolute;
  top: 50%;
  @include prefixer(transform, translateY(-50%));
}
@mixin center() {
  position: absolute;
  left: 50%;
  @include prefixer(transform, translateX(-50%));
}
@mixin middle-center() {
  position: absolute;
  top: 50%; left: 50%;
  @include prefixer(transform, translate(-50%, -50%));
}
@mixin position($position: relative, $coordinates: null null null null) {
  @if type-of($position) == list {
    $coordinates: $position;
    $position: relative;
  }
  $coordinates: unpack($coordinates);
  $offsets: (
    top: nth($coordinates, 1),
    right: nth($coordinates, 2),
    bottom: nth($coordinates, 3),
    left: nth($coordinates, 4)
  );
  position: $position;
  @each $offset, $value in $offsets {
    @if is-length($value) {
      #{$offset}: $value;
    }
  }
}
@mixin circle($size) {
  height: $size;
  width: $size;
  border-radius: 100%;
}
@mixin prefixer($property, $value, $important: false) {
  @if $important {
    -webkit-#{$property}: $value !important;
    -moz-#{$property}: $value !important;
    -ms-#{$property}: $value !important;
    -o-#{$property}: $value !important;
    #{$property}: $value !important;
  } @else {
    -webkit-#{$property}: $value;
    -moz-#{$property}: $value;
    -ms-#{$property}: $value;
    -o-#{$property}: $value;
    #{$property}: $value;
  }
}
@mixin transition($speed, $animation) {
  @include prefixer(transition, $speed $animation);
}
