// App Color Palette
$color-primary: #476CAE;
$color-primary-dark: #2B436B;
$color-primary-light: #DFE6F3;
$color-secondary: #162F4D;
$color-accent: #E94E36;
$color-content-bg: #FEFEFE;
$color-main-bg: #F5F8Fc;
$color-primary-orange-light: #E7A193;
$color-primary-orange: #D85940;
$color-vanilla-springtime: #FBE09D;
$color-devious-lilac: #CFB1FB;

// Material colors
$color-red: #f44336;
$color-pink: #e91e63;
$color-purple: #9c27b0;
$color-deep-purple: #673ab7;
$color-indigo: #3f51b5;
$color-blue: #2196f3;
$color-light-blue: #03a9f4;
$color-cyan: #00bcd4;
$color-teal: #009688;
$color-green: #4caf50;
$color-cloudy-green: #B6F6E5;
$color-light-green: #8bc34a;
$color-lime: #cddc39;
$color-yellow: #ffeb3b;
$color-amber: #d19f09;
$color-orange: #ff9800;
$color-deep-orange: #ff5722;
$color-brown: #795548;
$color-grey: #9e9e9e;
$color-blue-grey: #607d8b;

// Functional colors
$color-success: $color-green;
$color-error: $color-accent;
$color-warning: $color-amber;
$color-info: #5c89ad;
$color-text: #0f0f0f;
$color-inactive-text: #838383;
$color-white: #fff;
$color-black: #000;
$color-border: #c9c9c9;
$color-success-alert: #117314;


// Screening Status Colors
$color-match: #ff0000;
$color-noMatch: #026928;
$color-closeRelative: #ff1493;
$color-closeAssociate: #FF7F50;
$color-pendingAction: #ff4500;
$color-pendingVerification: #ff4500;
$color-underInvestigation: #1E90FF;
$color-possiblyMatched: #f54263;
$color-unspecified: #9e9194;

// Screening Status Light Colors
$color-match-light: lighten($color-match, 43%);
$color-noMatch-light: lighten($color-noMatch, 43%);
$color-closeRelative-light: lighten($color-closeRelative, 43%);
$color-closeAssociate-light: lighten($color-closeAssociate, 43%);
$color-pendingAction-light: lighten($color-pendingAction, 43%);
$color-pendingVerification-light: lighten($color-pendingVerification, 43%);
$color-underInvestigation-light: lighten($color-underInvestigation, 43%);
$color-possiblyMatched-light: lighten($color-possiblyMatched, 43%);
$color-unspecified-light: lighten($color-unspecified, 43%);

// Light colors
$color-primary-light: lighten($color-primary, 43%);
$color-secondary-light: lighten($color-secondary, 43%);
$color-accent-light: lighten($color-accent, 43%);
$color-success-light: lighten($color-success, 45%);
$color-error-light: lighten($color-error, 41%);
$color-warning-light: lighten($color-warning, 45%);
$color-light-bg: rgba(0, 0, 0, 0.026);
$color-report-light: lighten($color-deep-purple, 41%);
$color-secondary-report-light: lighten($color-purple, 41%);
$color-grey-light: lighten($color-grey, 25%);

// Links
$link-color: $color-primary;
$link-hover-color: $color-secondary;
$link-active-color: $color-secondary;
$link-decoration: none;
$link-hover-decoration: none;

// Fonts
$font-family: "Open Sans", sans-serif;
$font-code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
$font-size-base: 14px;
$font-size-lg: $font-size-base + 4px;
$font-size-md: $font-size-base + 2px;
$font-size-sm: $font-size-base - 2px;
$font-heading-1-size: ceil($font-size-base * 1.4);
$font-heading-2-size: ceil($font-size-base * 1.2);
$font-heading-3-size: ceil($font-size-base * 1.05);
$font-heading-4-size: ceil($font-size-base * 1);
$font-line-height: 1.5;
$font-icon-sm: 18px;
$font-icon-base: 22px;
$font-icon-lg: 26px;

// Size
$padding-xl: 30px; // Layout containers
$padding-lg: 24px; // containers
$padding-md: 16px; // small containers and buttons
$padding-sm: 12px; // Form controls and items
$padding-xs: 8px; // small items
$margin-xl: 30px;
$margin-lg: 24px;
$margin-md: 16px;
$margin-sm: 12px;
$margin-xs: 8px;
$border-radius-base: 4px;
$border-radius-sm: 2px;
$border-radius-lg: 6px;

// Animations
$ease-base-out: cubic-bezier(0.7, 0.3, 0.1, 1);
$ease-base-in: cubic-bezier(0.9, 0, 0.3, 0.7);
$ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
$ease-out-back: cubic-bezier(0.12, 0.4, 0.29, 1.46);
$ease-in-back: cubic-bezier(0.71, -0.46, 0.88, 0.6);
$ease-in-out-back: cubic-bezier(0.71, -0.46, 0.29, 1.46);
$ease-out-circ: cubic-bezier(0.08, 0.82, 0.17, 1);
$ease-in-circ: cubic-bezier(0.6, 0.04, 0.98, 0.34);
$ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);
$ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
$ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);

// Animation Speeds
$animation-duration-slow: 0.3s;
$animation-duration-base: 0.2s;
$animation-duration-fast: 0.1s;

// Shadow
$shadow-color: rgba(0, 0, 0, 0.15);
$shadow-1-up: 0 -2px 4px $shadow-color;
$shadow-1-down: 0 2px 4px $shadow-color;
$shadow-1-left: -2px 0 4px $shadow-color;
$shadow-1-right: 2px 0 4px $shadow-color;
$shadow-2: 0 4px 12px $shadow-color;
$shadow-3: 0 8px 24px $shadow-color;

// Media queries breakpoints
$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1400px;

$screen-xs-max-width: 460px;
$screen-sm-max-width: 540px;
$screen-md-max-width: 720px;
$screen-lg-max-width: 960px;
$screen-xl-max-width: 1140px;
$screen-xxl-max-width: 1400px;