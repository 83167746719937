@font-face {
  font-display: swap;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/lexend/Lexend-Black.ttf"),
    url("assets/fonts/lexend/Lexend-ExtraLight.ttf"),
    url("assets/fonts/lexend/Lexend-Light.ttf"),
    url("assets/fonts/lexend/Lexend-Medium.ttf"),
    url("assets/fonts/lexend/Lexend-Regular.ttf"),
    url("assets/fonts/lexend/Lexend-Thin.ttf");
}

@font-face {
  font-display: swap;
  font-family: "Lexend";
  font-style: normal;
  font-weight: 700;
  src: url("assets/fonts/lexend/Lexend-Bold.ttf"),
    url("assets/fonts/lexend/Lexend-ExtraBold.ttf"),
    url("assets/fonts/lexend/Lexend-SemiBold.ttf");
}

@font-face {
  font-display: swap;
  font-family: "Lexend";
  font-style: italic;
  font-weight: 500;
  src: url("assets/fonts/lexend/Lexend-BlackItalic.ttf"),
    url("assets/fonts/lexend/Lexend-ExtraLightItalic.ttf"),
    url("assets/fonts/lexend/Lexend-LightItalic.ttf"),
    url("assets/fonts/lexend/Lexend-MediumItalic.ttf"),
    url("assets/fonts/lexend/Lexend-RegularItalic.ttf"),
    url("assets/fonts/lexend/Lexend-ThinItalic.ttf");
}

@font-face {
  font-display: swap;
  font-family: "Lexend";
  font-style: italic;
  font-weight: 700;
  src: url("assets/fonts/lexend/Lexend-BoldItalic.ttf"),
    url("assets/fonts/lexend/Lexend-ExtraBoldItalic.ttf"),
    url("assets/fonts/lexend/Lexend-SemiBoldItalic.ttf");
}

:root {
  --logo-width: 120px;
  --logo-height: 40px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body,
html {
  background-color: #eef4f8 !important; /*f5f8fc old value*/
  font-family: "Lexend";
  line-height: 1.5;
}

body:not(.menu-on-top).desktop-detected {
  min-height: auto !important;
}

.cell-nowrap {
  white-space: nowrap;
}

.multiselect-dropdown .disabled span:not(.selected-item) {
  color: #FFF !important;
}

#content {
  padding: 10px 13px !important;
}

body {
  background-image: none !important;
}

.client-route-content {
  margin-bottom: 75px !important;
}

.sa-section {
  display: flex;
}

.sa-section .col {
  flex: 1 1 0;
  margin: 0;
}

.sa-page-content {
  padding: 20px !important;
}

.page-content {
  padding: 10px !important;
}

.show-stat-microcharts > div {
  border: 1px solid #efefef !important;
  margin: 0 !important;
}

.pb-60 {
  padding-bottom: 60px !important;
  margin-top: -30px;
}

.show-stat-microcharts > :last-child {
  border-right: 1px solid #eaeaea !important;
}

.show-stat-microcharts {
  margin: 0 !important;
}

.time-zone {
  float: right;
  right: 10px;
  color: #5d5d5d;
  display: flex;
  align-items: center;
  width: 350px;
  position: relative;
  bottom: 6px;
  background: #f1f1f1;
  padding: 0 10px;
  border-radius: 2px;
  transition: 0.2s ease-in-out;
}

.time-zone:hover {
  background: #e6e6e6;
}

.time-zone dx-select-box {
  width: 100%;
}

.time-zone .dx-dropdowneditor-icon {
  transform: rotate(180deg);
}

.time-zone *:after,
.time-zone *:before,
.time-zone * {
  border: none !important;
  border-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}

.time-zone .dx-texteditor-input {
  font-size: 1em !important;
}

.informer {
  margin-right: 15px !important;
}

.incomplete-status i,
.complete-status i {
  margin-right: 5px;
}

.complete-status {
  color: rgb(17 116 2);
}

.incomplete-status {
  color: #d08700;
  letter-spacing: 0.04rem;
}

.full-center {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: justify !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.dx-texteditor.dx-editor-outlined.dx-state-disabled,
.dx-texteditor.dx-editor-outlined.dx-state-readonly,
.dx-texteditor.dx-editor-outlined.dx-state-readonly.dx-state-hover {
  background: #eee !important;
  opacity: 1 !important;
}

.dx-texteditor.dx-editor-outlined.dx-state-disabled .dx-placeholder,
.dx-texteditor.dx-editor-outlined.dx-state-readonly .dx-placeholder,
.dx-texteditor.dx-editor-outlined.dx-state-readonly.dx-state-hover
  .dx-placeholder,
.dx-texteditor.dx-editor-outlined.dx-state-disabled input.dx-texteditor-input,
.dx-texteditor.dx-editor-outlined.dx-state-readonly input.dx-texteditor-input,
.dx-texteditor.dx-editor-outlined.dx-state-readonly.dx-state-hover
  input.dx-texteditor-input {
  color: #545454 !important;
}

.info-sm {
  font-style: italic;
  color: #999999;
  position: relative;
  top: 3px;
  display: block;
}

.spaced {
  line-height: 22px !important;
}

.dx-button.dx-button-success {
  background-color: #4caf50;
  margin: 0 5px 0 15px;
  /* max-width: 100px; */
}

.eGOV-profile {
  border-radius: 50%;
  -webkit-box-shadow: 0 0 3px 1px #00000038;
  box-shadow: 0 0 3px 1px #00000038;
  width: 70px;
  height: auto;
}

.sumsub-profile {
  -webkit-box-shadow: 0 0 3px 1px #00000038;
  box-shadow: 0 0 3px 1px #00000038;
  width: 70px;
  height: auto;
}

.eGOV-sh {
  -webkit-box-shadow: 0 0 3px 1px #00000038;
  box-shadow: 0 0 3px 1px #00000038;
}

.eGOV-property {
  min-width: 110px;
  display: inline-block;
}

.eGOV-wrapper,
.eGOV-pair {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.eGOV-wrapper.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.eGOV-wrapper.column .eGOV-pair {
  margin-bottom: 25px !important;
}

.eGOV-pair {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 10px;
  margin-bottom: 15px;
}

.eGOV-pair div {
  font-size: 1.1em;
}

.eGOV-title {
  font-weight: bold;
  margin-right: 10px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.eGOV-section {
  font-size: 1.6em;
}

.eGOV-category {
  margin-bottom: 20px;
  text-decoration: underline;
  font-weight: bold;
  font-size: 1.4em;
}

.eGOV-tab-title {
  margin-bottom: 30px;
  text-decoration: underline;
  font-size: 2em;
}

.dx-button.dx-button-success:hover {
  background-color: #5d855d;
}

.modal-header {
  padding: 15px 20px !important;
}

.mr-1 {
  margin-right: 10px;
}

.mr-2 {
  margin-right: 15px;
}

.mr-5 {
  margin-right: 5px;
}

body.modal-open {
  overflow: visible !important;
}

.mb-xs {
  margin-bottom: 4px;
}

.ng2-tag-input.dark {
  border: 1px solid #b0b0b0 !important;
  border-radius: 3px !important;
  padding: 5px 10px !important;
}

.ng2-tag-input.dark tag {
  background: #337ab7 !important;
  color: white !important;
}

.sticky,
.sticky-client {
  background: #f5f8fc !important;
}

tbody > .ds-sep {
  border-top: 2px solid #ababab !important;
  border-top-style: dashed !important;
}

tbody > .ds-sep ~ .ds-sep {
  border-top: none !important;
}

.kompany-selector {
  background: white !important;
}

.kompany-selector .dx-placeholder {
  display: flex;
  align-items: center !important;
}

.kompany-selector .dx-texteditor-input {
  padding-left: 15px !important;
  font-size: 1.11em !important;
}

#kompany-results-json .ngx-json-viewer {
  border: 1px solid lightgrey !important;
  background: white !important;
}

#kompany-results-json .ngx-json-viewer * {
  color: rgba(0, 0, 0, 0.87) !important;
}

#kompany-results-json .ngx-json-viewer section.segment {
  margin: 0 !important;
  padding: 0 !important;
  border-bottom: 1px solid lightgrey;
}

#kompany-results-json
  .ngx-json-viewer
  .segment-type-null
  > .segment-main
  > .segment-value {
  background: white !important;
}

#kompany-results-json .ngx-json-viewer section.segment:last-child {
  border-bottom: none !important;
}

#kompany-results-json .ngx-json-viewer .segment:nth-child(even) {
  background-color: #f7f7f7 !important;
}

#kompany-results-json .ngx-json-viewer span.segment-key {
  border-right: 1px solid lightgray;
  padding: 8px 8px 8px 20px !important;
  display: inline-block !important;
  width: 160px !important;
  min-width: 160px !important;
  text-transform: capitalize !important;
}

#kompany-results-json .ngx-json-viewer .segment-main {
  display: flex !important;
  align-items: center !important;
}

#kompany-results-json .ngx-json-viewer span.segment-separator {
  visibility: hidden !important;
  margin-right: 15px !important;
}

#kompany-results-json .ngx-json-viewer .segment .children {
  padding-bottom: 15px !important;
}

#kompany-results-json .ngx-json-viewer .segment .segment-main .toggler {
  left: 0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-left: 5px !important;
  margin-top: 0 !important;
}

.kompany-fees-wrapper {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  max-width: 96.5%;
  margin: 15px auto;
  border-top: 1px solid #e6e6e6;
  padding-top: 25px;
  position: relative;
}

.kompany-fee-warning {
  position: absolute;
  right: 0;
  top: 40px;
  width: 518px;
}

.kompany-total-fees span {
  font-size: 1.05em;
}

.kompany-fees-fieldset {
  border: 1px solid #9c9c9c !important;
  padding: 15px !important;
  border-color: #cbcbcb !important;
  border-radius: 2px !important;
  border-style: dashed !important;
  background: #f8f8f8b5 !important;
  box-shadow: 5px 8px 7px -2px #00000014 !important;
  max-width: 295px !important;
  overflow: auto;
}

.kompany-fees-legend {
  border: none;
  font-size: 1.7em;
  padding: 0 !important;
  display: inline-block;
  width: auto;
  margin: 0 !important;
}

span.kompany-fee-cost {
  font-weight: bold;
}

.kompany-fees-wrapper .kompany-fee-type {
  min-width: 145px;
  display: inline-block;
}

.kompany-fees-wrapper.sz-lg .kompany-fee-type {
  min-width: 220px !important;
}

.kompany-fee {
  padding: 7px 0;
  border-bottom: 1px solid #c7c7c7;
  border-bottom-style: dashed;
  display: flex;
}

.kompany-total-fees {
  margin-top: 14px;
  display: flex;
  font-size: 1.1em;
  font-weight: bold;
}

.kompany-fees-wrapper .kompany-total-fees-label {
  min-width: 145px;
  display: inline-block;
}

.kompany-fees-wrapper.sz-lg .kompany-total-fees-label {
  width: 220px !important;
}

.kompany-table {
  text-align-last: left !important;
  max-width: 97% !important;
  margin: 0 auto 35px auto !important;
  border: 1px solid #e6e6e6 !important;
}

.kompany-table tr:nth-child(even) {
  background-color: #f6f6f6 !important;
}

.cs-sr {
  font-weight: 600;
  color: #434343;
  font-size: 1.5em;
  position: relative;
  z-index: 2;
  top: 40px;
  left: 15px;
}

.kompany-table-title {
  position: relative;
  top: 33px;
  font-weight: 600;
  left: 14px;
  letter-spacing: 0.05rem;
}

.kompany-table .st {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.85em;
  letter-spacing: 0.05rem;
  border-radius: 2px;
}

.kompany-table .st.st-1 {
  background: #f2f9ff;
  border: 1px solid #d1d9fa;
  color: #546b7f;
}

.dx-datagrid-headers .dx-searchbox .dx-placeholder::before,
.dx-datagrid-headers .dx-searchbox .dx-texteditor-input,
.dx-datagrid-rowsview .dx-searchbox .dx-placeholder::before,
.dx-datagrid-rowsview .dx-searchbox .dx-texteditor-input {
  padding-left: 46px !important;
}

.final-cost {
  font-weight: 800;
  font-size: 1.3em;
  margin-bottom: 15px;
  position: relative;
  bottom: 10px;
}

.kompany-table .st.st-2 {
  color: #07690e;
  background: #e1f8da;
  border: 1px solid #b0d9a4;
}

.section-title {
  border-bottom: 1px solid #bdbdbd;
  border-bottom-style: dashed;
  padding-bottom: 5px;
  margin-bottom: 25px;
  letter-spacing: 0.01rem;
  color: #3c3c3c;
}

.kompany-table .st.st-3 {
  color: #ab0800;
  background: #ffe5e1;
  border: 1px solid #ffc4c1;
}

.kompany-table-title i {
  margin-right: 10px;
}

.kompany-table td,
.kompany-table th {
  vertical-align: middle !important;
}

.kompany-table th:first-child {
  width: 35%;
}

.kompany-table th:last-child {
  min-width: 90px !important;
}

.kompany-table th:not(.kompany-table th:first-child) {
  width: 25%;
}

.kompany-check {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.custom-modal-footer {
  height: 60px;
  max-width: 98.55%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.custom-modal-footer.bt-grey {
  border-top: 1px solid #dddddd;
  margin-top: 35px;
}

.mtk20 {
  color: #ff7600 !important;
}

.mt-4 {
  margin-top: 40px;
}

.mt-10 {
  margin-top: 10px !important;
}

.lg-reset {
  position: relative;
  bottom: 24px;
}

.json-viewer-wrapper {
  padding: 20px;
  background: #fcfcfc;
  box-shadow: rgb(67 71 85 / 27%) 0px 0px 0.25em,
    rgb(90 125 188 / 5%) 0px 0.25em 1em;
}

.portal-tabs-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

#portal-tabs {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.document-template-wrapper {
  position: absolute;
  right: 100px;
  bottom: 1px;
  background: white;
}

input.firm-criterion-answer::-webkit-outer-spin-button,
input.firm-criterion-answer::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].firm-criterion-answer {
  -moz-appearance: textfield;
}

.imp-score {
  font-weight: 600;
  letter-spacing: 0.04rem;
  font-size: 0.95em;
  position: relative;
  top: 3px;
  color: white;
  display: table;
  padding: 3px 11px;
  border-radius: 20px;
  background: rgb(74, 74, 74);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

#portal-tabs::-webkit-scrollbar {
  height: 9px !important;
}

#portal-tabs::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

#portal-tabs::-webkit-scrollbar-thumb {
  background: rgb(206, 206, 206) !important;
}

#portal-tabs::-webkit-scrollbar-thumb:hover {
  background: rgb(194, 194, 194) !important;
}

.dx-dropdownbox .dx-placeholder:before {
  padding: 9px 10px !important;
}

.dx-dropdownbox .dx-texteditor-input {
  /* padding-top: 17px !important;*/
  font-size: 1.15em !important;
}

.dx-datebox .dx-placeholder {
  top: -8px !important;
}

.dx-datagrid-search-panel {
  height: 37px !important;
}

.dx-datagrid-search-panel .dx-placeholder {
  top: -5px !important;
}

.dx-datagrid-search-panel .dx-icon.dx-icon-search {
  top: 53% !important;
}

.dx-widget.dx-button.dx-button-normal.dx-button-mode-text {
  background: whitesmoke;
  border: 1px solid #e5e5e5;
}

.dx-widget.dx-button.dx-button-normal.dx-button-mode-text:hover {
  background: #ededed !important;
}

mention-list {
  top: 117px !important;
}

.m-1 {
  margin-top: 15px;
  margin-bottom: 15px;
}

textarea {
  max-width: 100%;
}

.tree-structure-widget {
  overflow: auto;
  -webkit-box-shadow: 0 2px 5px 0px #a0a0a04d !important;
  box-shadow: 0 2px 5px 0px #a0a0a04d !important;
  /* margin-top: 10px; */
}

/* THIS IS THE PROPER STYLING TO APPLY TO THE WIDGET THAT SHOWS THE TREE STRUCTURE IN FULL SCREEN MODE */
#jarviswidget-fullscreen-mode {
  left: 65px !important;
  max-width: calc(100% - 65px);
}

.smart-accordion-default {
  border-radius: 10px;
}

.custom-field-fg {
  width: 210px !important;
  margin-right: 20px !important;
}

.close span {
  font-size: 1.5em;
  position: relative;
  top: 5px;
}

.inline-group.d-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.hide-text-overflow {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

.dx-placeholder {
  font-size: 1.1em;
}

[id^="profile"] {
  min-width: 130px;
}

#gridBeneficiaries .dx-template-wrapper {
  display: inline-block !important;
}

.dx-dropdown-custom-style {
  margin-top: 8px;
  height: 40px;
}

.dx-dropdown-custom-style input {
  padding: 7px !important;
}

.cf-dynamic-grid .dx-gridbase-container > .dx-datagrid-rowsview.dx-scrollable {
  border-left: 1px solid #e6e6e6 !important;
  border-right: 1px solid #e6e6e6 !important;
}

.cf-dynamic-grid {
  margin: 20px 0 !important;
}

.cf-dynamic-grid .dx-datagrid-headers {
  border-left: 1px solid #e6e6e6 !important;
  border-right: 1px solid #e6e6e6 !important;
}

.cf-dynamic-grid .dx-scrollable-content {
  border-bottom: 1px solid #e6e6e6 !important;
}

.cf-dynamic-grid td.dx-datagrid-action,
.cf-dynamic-grid .dx-texteditor-input,
.cf-dynamic-grid td[class*="dx-col"] {
  text-align: left !important;
}

#search-criteria-accordion {
  width: 100%;
  display: unset;
}

#search-criteria-accordion .panel-body {
  padding: 0 !important;
}

#search-criteria-accordion .wrapper {
  padding: 5px 19px;
  margin: 0 12px;
  border-bottom: 1px solid #c8c8c8;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#search-criteria-accordion .wrapper span {
  font-size: 0.95em;
}

#search-criteria-accordion .key {
  padding-right: 10px;
}

#search-criteria-accordion .value {
  font-weight: bold;
}

#scroll-to-top {
  position: fixed;
  display: none;
  border-radius: 50%;
  right: 15px;
  bottom: 63px;
  -webkit-box-shadow: 0 1px 5px 1px #0000004f;
  box-shadow: 0 1px 5px 1px #0000004f;
  cursor: pointer;
  width: 55px;
  height: 55px;
  background: #3276b1;
  z-index: 10;
}

#scroll-to-top:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-scrollbar-vertical.dx-scrollbar-hoverable {
  width: 15px;
}

.dx-scrollbar-vertical .dx-scrollable-scroll {
  width: 15px;
}

.custom-fieldset-eval-quest {
  border: 1px solid #e0e0e0;
  position: relative;
  padding: 30px;
  margin: 40px 0;
  border-radius: 1px;
}

.custom-fieldset-eval-quest .custom-fieldset-title {
  position: absolute;
  top: -15px;
  left: 15px;
  font-size: 1.6em;
  display: inline-block;
  padding: 0px 15px;
}

.evaluation-answers-section > h2 {
  text-align: center;
  font-weight: 750;
  font-size: large;
}

fieldset.dashed {
  border-bottom: 1px solid #c3c3c3;
  border-style: dashed;
  padding: 10px 0 25px 0;
}

#scroll-to-top #scroll-to-top-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  color: white;
  -webkit-transform: translate(-50%, -100%);
  -ms-transform: translate(-50%, -100%);
  transform: translate(-50%, -100%);
  font-size: 23px;
}

.p-20 {
  padding: 20px !important;
}

i.check-icon {
  color: green !important;
  opacity: 0.8 !important;
}

i.warning-icon {
  color: orange !important;
  opacity: 0.8 !important;
}

i.sucess-icon {
  color: green !important;
  opacity: 0.8 !important;
}

.alert-sucess {
  border-color: #8ac38b !important;
  color: #356635 !important;
  background-color: #cde0c4 !important;
}

.valid-directory {
  cursor: pointer;
  color: rgb(9 76 200) !important;
}

.message-text {
  word-break: break-all !important;
}

.chat-body li.message .message-text {
  margin-right: 35px;
}

#scroll-to-top #scroll-to-top-text {
  color: white;
  font-weight: bold;
  font-size: 13px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -10%);
  -ms-transform: translate(-50%, -10%);
  transform: translate(-50%, -10%);
}

.bc-white {
  background: white !important;
}

.checkbox input[type="checkbox"]:disabled ~ i {
  background: #eeeeee !important;
}

input[type="checkbox"],
input[type="radio"] {
  transform: scale(1.5, 1.5);
  margin-left: 1em;
}

input[type="checkbox"]:hover,
input[type="radio"]:hover {
  cursor: pointer;
}

.radio-input {
  margin: 1em 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.checkbox-input {
  margin: 1em 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.valid-password-condition {
  color: rgb(98, 221, 98) !important;
}

.passwordMatch {
  border: 1px solid rgb(98, 221, 98) !important;
  border-radius: 5px;
}

.passwordNotMatch {
  border: 1px solid rgb(216, 37, 37) !important;
  border-radius: 5px;
}

.isActiveHelpText {
  cursor: help;
  position: relative;
  left: 85px;
  top: 3px;
}

.navigation-search-li {
  margin-top: 25px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navigation-search-li i.search-icon {
  position: absolute;
  left: 22px;
  color: grey;
}

.no-search-results i.fa-search {
  margin-bottom: 15px;
  font-size: 1.7em;
}

.navigation-search-li i.search-clean-icon {
  position: absolute;
  right: 15px;
  color: #9c9c9c;
  font-size: 16px;
  cursor: pointer;
  background: white;
  padding: 3px;
}

.navigation-search-input {
  max-width: 90%;
  border: none;
  padding: 7px 10px 7px 32px;
  border-radius: 2px;
  -webkit-box-shadow: rgb(50 50 93 / 0%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  box-shadow: rgb(50 50 93 / 0%) 0px 2px 5px -1px,
    rgb(0 0 0 / 30%) 0px 1px 3px -1px;
  -webkit-transition: 0.05s ease-in-out;
  -o-transition: 0.05s ease-in-out;
  transition: 0.05s ease-in-out;
}

.btn-group.dropdown.save-and-close.d-flex.float-right .caret {
  position: relative;
  bottom: 14px;
}

.rcm-dts {
  background: #278f2e;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-size: 0.65em;
  bottom: 1px;
  position: relative;
  padding: 4px 7px;
  left: 3px;
}

input.navigation-search-input:focus {
  -webkit-box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px !important;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px !important;
}

.no-search-results {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 30px;
  color: white;
}

.no-search-results.cs-client {
  color: black;
}

.no-search-results span {
  font-size: 1.3em;
  color: white;
  text-align: center !important;
}

.navigation-search-li.cs-client {
  margin: 0 !important;
  border: 1px solid #cccccc !important;
  padding: 10px 0 !important;
  border-bottom-color: transparent !important;
}

.navigation-search-input.cs-client {
  box-shadow: none !important;
  border: 1px solid #cccccc !important;
  max-width: 100% !important;
  width: 100% !important;
  /* padding: 7px 0 7px 30px !important; */
}

.navigation-search-input.cs-client:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-color: #9b9b9b !important;
}

.navigation-search-li i.search-icon.cs-client {
  left: 34px !important;
}

.navigation-search-li i.search-clean-icon.cs-client {
  right: 30px !important;
}

.no-search-results.cs-client {
  font-size: 0.9em !important;
  background: #f5f5f5 !important;
  padding: 15px !important;
  margin: 20px auto !important;
  box-shadow: rgb(50 50 93 / 0%) 0px 2px 5px -1px,
    rgb(0 0 0 / 52%) 0px 1px 3px -1px !important;
  border-radius: 2px !important;
}

.jarviswidget > div {
  border: 1px solid #eaeaea !important;
  border-radius: 3px;
}

.jarviswidget > header {
  border: none !important;
  background: #e4edf6 !important;
  -webkit-box-shadow: 0 1px 5px 0px #b3b3b34d !important;
  box-shadow: 0 1px 5px 0px #b3b3b34d !important;
  color: #0341b0 !important;
}

.custom-fieldset-eval-results-quest > div {
  -webkit-box-shadow: 0 1px 5px 0px #979494 !important;
  box-shadow: 0 1px 5px 0px #979494 !important;
}

.custom-fieldset-eval-quest .header,
.custom-fieldset-eval-results-quest .header {
  height: 100%;
  padding: 0.5em;
  display: flex;
  color: #0341b0 !important;
}

.custom-fieldset-eval-quest .widget-header,
.custom-fieldset-eval-quest .widget-body,
.custom-fieldset-eval-results-quest .widget-header,
.custom-fieldset-eval-results-quest .widget-body {
  padding: 0.5em;
}

.custom-fieldset-eval-quest .widget-header h2.pull-left,
.custom-fieldset-eval-quest .widget-header h2.pull-right,
.custom-fieldset-eval-results-quest .widget-header h2.pull-left,
.custom-fieldset-eval-results-quest .widget-header h2.pull-right {
  margin: 0;
  padding: 0;
}

.mat-autocomplete-panel {
  position: fixed !important;
  max-width: 335px !important;
  top: 205px;
  left: 70px !important;
  border: 1px solid lightgrey;
  background-color: white;
  z-index: 999999 !important;
}

.mat-autocomplete-panel.toggle {
  top: 235px !important;
}

.mat-option {
  background-color: rgba(253, 252, 252, 0.938);
}

.mat-option:hover {
  background-color: rgb(211, 211, 211);
}

#search-clients header {
  background: white !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#search-clients .jarviswidget {
  margin: 0;
}

.emph {
  display: block;
  border-bottom: 1px dashed #999;
  -webkit-text-decoration-style: dotted;
  text-decoration-style: dotted;
  font-size: 1.3em;
  color: black;
  font-weight: 500;
  text-decoration: inherit;
  margin-bottom: 10px;
}

#search-clients div,
#search-clients a {
  border: none;
}

#search-clients .jarviswidget-ctrls {
  border: 1px solid #9d9dff !important;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
  max-height: 31px !important;
}

.jarviswidget .widget-icon i,
.jarviswidget .button-icon {
  color: #0341b0 !important;
}

.ngx-datatable.bootstrap .datatable-header {
  background-color: #e4edf6 !important;
  color: #062d7d !important;
}

.datatable-row-wrapper:nth-child(even) {
  background: #f6f6f7 !important;
}

.well {
  background: white;
  margin-top: 0.5em;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#required-fields-grid {
  overflow: inherit !important;
}

#required-fields-grid .datatable-body-cell {
  overflow-x: inherit !important;
}

/* Track */

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-grid-icon {
  cursor: pointer;
}

/* LEFT SIDEBAR */

#left-panel {
  background: #ececec;
}

span.aliensun-link-icon {
  display: none;
}

.instructions-num {
  margin-left: 10px !important;
}

.smart-style-1 nav a {
  color: #6f6f6f !important;
}

.smart-style-1 nav a:hover {
  color: #003da6 !important;
}

.user-options-icon {
  width: 20px;
  font-size: 1.15em;
  margin: 0 !important;
  padding: 0 !important;
}

/* .smart-style-1 nav li.active a {
  color: #ec7700 !important;
} */

/* NAVBAR */

#header {
  background-image: none !important;
  background-color: #ffffff !important;
  -webkit-box-shadow: 0px 2px 3px 0px #44444430 !important;
  box-shadow: 0px 2px 3px 0px #44444430 !important;
}

.smart-style-1 #logo-group {
  background: #ffffff !important;
}

.smart-style-1 .custom-logo-width-main {
  width: var(--logo-width) !important;
  height: var(--logo-height);
}

.client-dropdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 55px;
}

.header-dropdown-list > li > .dropdown-toggle {
  margin: 0;
}

#header .flex-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#header .d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  height: 100%;
}

#header .client-options-dropdown {
  height: 100%;
  min-width: 150px;
  margin-left: 30px;
  padding: 0 30px 0 15px;
  background: #f9f9f9;
  border-right: 1px solid #eaeaea;
  border-left: 1px solid #eaeaea;
}

.dx-datagrid,
.dx-group-panel-message,
.informer span.name {
  font-family: "Lexend", sans-serif !important;
}

#main {
  margin-left: 0 !important;
  /* overflow: auto; */
  /* margin-bottom: 100px !important; */
}

.modal {
  background: rgba(0, 0, 0, 0.5) !important;
}

.modal-backdrop {
  display: none !important;
}

.bg-color-transparent {
  background-color: transparent;
}

.user-username {
  font-weight: 700;
  font-size: 1.1em;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 2px;
  white-space: nowrap;
}

h4.count {
  font-weight: 600 !important;
}

.user-role {
  font-size: 0.9em;
}

#user-options-dropdown {
  left: -157px !important;
  width: 210px;
}

#gridClientAuthorizedPersons tr.dx-row.dx-data-row.dx-row-lines td {
  padding: 0 20px !important;
}

#gridClientAuthorizedPersons .progress {
  position: relative !important;
  top: 10px !important;
}

.progress.progress-lg {
  height: 31px !important;
  border: 1px solid #bdbdbd;
}

.progress.progress-lg .progress-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

#system-account-tab .dx-template-wrapper {
  overflow: auto;
}

.sticky-arrangement {
  position: sticky;
  top: 0;
  background-color: #f5f8fc !important;
  z-index: 900;
  padding-left: 242px;
  margin-bottom: 0;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.custom-list-group {
  margin-top: 28px !important;
  border: 1px solid #ddd !important;
  min-height: 85vh !important;
}

.fa-mr-5 {
  min-width: 20px;
}

.custom-list-group a {
  border-radius: 0 !important;
  border-right: none !important;
  border-left: none !important;
}

#header .client-options-dropdown .dropdown-arrow {
  color: rgb(78, 78, 78);
  font-size: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(-80%, -50%);
  -ms-transform: translate(-80%, -50%);
  transform: translate(-80%, -50%);
}

#translate-language-dropdown {
  left: -119px !important;
}

.widget-footer {
  background: white !important;
}

.user-options-link {
  display: block !important;
  padding: 4px 10px !important;
  color: #1e1e1e;
  background: white !important;
}

.user-options-link:hover {
  color: rgb(10, 108, 189) !important;
}

.dropdown-menu.client > li a {
  display: block;
  padding: 5px 10px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: rgb(92, 92, 92);
  white-space: nowrap;
  background: white !important;
}

.dropdown-menu.client > li a:hover {
  color: rgb(58, 58, 58);
  background: rgb(241, 241, 241) !important;
}

.dropdown-menu.client > li a i {
  margin-right: 5px;
}

.smart-style-1 #logo-group span#activity {
  background: white;
  border-color: white;
  color: #656565;
}

#activity-badge {
  padding: 0 !important;
  width: 15px !important;
  height: 15px !important;
  top: 3px;
  position: absolute;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#activity {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  position: relative;
}

#notifications-dropdown {
  top: 45px;
  background-color: white;
  border-radius: 4px;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

#notifications-dropdown .ajax-notifications {
  height: 369px;
  border-top-color: #e1e1e1;
  border-bottom-color: #e1e1e1;
}

#notifications-dropdown .last-update-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 3px;
  padding: 5px;
}

#notifications-dropdown .notification-body {
  padding: 0 !important;
}

#notifications-dropdown .last-update-text {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
  color: #0000009b;
  letter-spacing: 0.01rem;
}

#notifications-dropdown .c-check {
  transform: rotate(10deg) !important;
  display: inline-block;
}

#notifications-dropdown .c-check.c-check-1 {
  position: relative;
  left: 3px;
}

#notifications-dropdown .refresh-nt {
  color: #3276b1;
  box-shadow: rgb(60 64 67 / 15%) 0px 1px 2px 0px,
    rgb(60 64 67 / 14%) 0px 1px 3px 1px;
  padding: 1px 8px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

#notifications-dropdown .refresh-nt:hover {
  box-shadow: rgb(60 64 67 / 3%) 0px 1px 2px 0px,
    rgb(60 64 67 / 3%) 0px 1px 3px 1px;
  background: rgba(50, 118, 177, 0.074);
}

#notifications-dropdown .nt-head {
  margin: 0;
  padding: 0px 5px 7px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#notifications-dropdown .nt-head .nt-box {
  font-weight: bold;
  font-size: 1.1em;
  letter-spacing: 0.02rem;
  color: #4a4a4a;
}

#notifications-dropdown .nt-head .mark-read {
  color: #1577c8;
  padding: 3px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

#notifications-dropdown .nt-head .mark-read:hover {
  color: #025da7;
  background: #1577c812;
}

.smart-style-1 #logo-group span#health-check {
  background: white;
  border-color: white;
  color: #656565;
}

#health-check-badge {
  padding: 0 !important;
  width: 50px !important;
  height: 15px !important;
}

#health-check {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  position: relative;
}

#notifications-dropdown {
  top: 53px;
  left: -202px;
  background-color: white;
  border-radius: 4px;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

#notifications-dropdown::before {
  left: 227px !important;
}

#notifications-dropdown::after {
  margin-left: 48px !important;
}

#notifications-dropdown .ajax-notifications {
  height: 369px;
  border-top-color: #e1e1e1;
  border-bottom-color: #e1e1e1;
}

#notifications-dropdown .last-update-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 3px;
  padding: 5px;
}

#notifications-dropdown .notification-body {
  padding: 0 !important;
}

#notifications-dropdown .last-update-text {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
  color: #0000009b;
  letter-spacing: 0.01rem;
}

#notifications-dropdown .c-check {
  transform: rotate(10deg) !important;
  display: inline-block;
}

#notifications-dropdown .c-check.c-check-1 {
  position: relative;
  left: 3px;
}

#notifications-dropdown .refresh-nt {
  color: #3276b1;
  box-shadow: rgb(60 64 67 / 15%) 0px 1px 2px 0px,
    rgb(60 64 67 / 14%) 0px 1px 3px 1px;
  padding: 1px 8px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

#notifications-dropdown .refresh-nt:hover {
  box-shadow: rgb(60 64 67 / 3%) 0px 1px 2px 0px,
    rgb(60 64 67 / 3%) 0px 1px 3px 1px;
  background: rgba(50, 118, 177, 0.074);
}

#notifications-dropdown .nt-head {
  margin: 0;
  padding: 0px 6px 7px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#notifications-dropdown .nt-head .nt-box {
  font-weight: bold;
  font-size: 1.15em;
  letter-spacing: 0.02rem;
  color: #292929;
}

#notifications-dropdown .nt-head .mark-read {
  color: #1577c8;
  padding: 3px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

#notifications-dropdown .nt-head .mark-read:hover {
  color: #025da7;
  background: #1577c812;
}

#health-check-dropdown {
  top: 53px;
  left: -207px;
  height: 456px !important;
  background-color: white;
  border-radius: 4px;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
}

#health-check-dropdown::before {
  left: 227px !important;
}

#health-check-dropdown::after {
  margin-left: 48px !important;
}

#health-check-dropdown .ajax-notifications {
  height: 369px;
  border-top-color: #e1e1e1;
  border-bottom-color: #e1e1e1;
}

#health-check-dropdown .last-update-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 3px;
  padding: 5px;
}

#health-check-dropdown .notification-body {
  padding: 0 !important;
}

#health-check-dropdown .last-update-text {
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: bold;
  color: #0000009b;
  letter-spacing: 0.01rem;
}

#health-check-dropdown .c-check {
  transform: rotate(10deg) !important;
  display: inline-block;
}

#health-check-dropdown .c-check.c-check-1 {
  position: relative;
  left: 3px;
}

#health-check-dropdown .refresh-nt {
  color: #3276b1;
  box-shadow: rgb(60 64 67 / 15%) 0px 1px 2px 0px,
    rgb(60 64 67 / 14%) 0px 1px 3px 1px;
  padding: 1px 8px;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

#health-check-dropdown .refresh-nt:hover {
  box-shadow: rgb(60 64 67 / 3%) 0px 1px 2px 0px,
    rgb(60 64 67 / 3%) 0px 1px 3px 1px;
  background: rgba(50, 118, 177, 0.074);
}

#health-check-dropdown .nt-head {
  margin: 0;
  padding: 0px 6px 7px 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#health-check-dropdown .nt-head .nt-box {
  font-weight: bold;
  font-size: 1.15em;
  letter-spacing: 0.02rem;
  color: #292929;
}

#health-check-dropdown .nt-head .mark-read,
.goto-analysis {
  color: #1577c8;
  padding: 3px;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.goto-analysis {
  position: relative;
  left: 30px;
}

.goto-analysis i {
  margin-left: 2px;
}

#health-check-dropdown .nt-head .mark-read:hover,
.goto-analysis:hover {
  color: #025da7;
  background: #1577c812;
}

.hc-popup-title {
  font-weight: bold;
  font-size: 1.15em;
  letter-spacing: 0.02rem;
}

#activity b.badge {
  right: -5px;
  top: -10px;
}

#activity .not-bell {
  cursor: pointer;
  font-size: 18px;
}

#health-check b.badge {
  position: absolute;
  top: -10px;
  right: -45px;
  cursor: pointer;
  background: #0091d9;
  display: inline-block;
  font-size: 10px;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  color: #fff;
  font-weight: 700;
  padding: 2px 4px 3px;
  text-align: center;
  line-height: normal;
}

#health-check .not-bell {
  cursor: pointer;
  font-size: 18px;
}

/* GENERAL */

.custom-text-color-black {
  color: black !important;
}

.custom-text-color-grey {
  color: #5f5f5f !important;
  font-size: 0.85em;
}

ul.list-style-none {
  /* margin: 5px 0 5px !important; */
  overflow: auto;
  white-space: nowrap;
  cursor: pointer;
}

.color-red {
  color: #a90329;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] {
  background-color: #eee !important;
  opacity: 1;
}

.color-green {
  color: #739e73;
}

.MsgTitle strong {
  font-size: 22px;
}

ul.list-style-none::-webkit-scrollbar-thumb {
  background: transparent;
}

ul.list-style-none:hover::-webkit-scrollbar-thumb {
  background: #888;
}

ul.list-style-none .wrapper {
  text-align: right;
}

ul.list-style-none li {
  list-style: none;
  /* border-right: 1px solid grey; */
  display: inline-block;
}

ul.list-style-none li:last-child {
  border-right-color: transparent;
}

/* ul.list-style-none li:first-child {
  border-left: 1px solid grey;
} */

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.dg-delete-icon {
  font-size: 1.2em;
  color: white;
  background: #a90329;
  cursor: pointer;
  position: relative;
  top: 12px;
  border-radius: 1px;
  padding: 5px 10px 5px 10px;
}

.btn-toolbar {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  flex-wrap: wrap;
}

.btn {
  font-size: 12.5px !important;
}

.d-flex.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.d-flex.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.d-flex.column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.d-flex.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.d-flex.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}

.health-check-analysis ul {
  overflow-wrap: break-word !important;
  padding-left: 25px;
}

tr.low-space-warning {
  background: #e9ae052b;
}

td.low-space-warning {
  font-weight: bold;
  color: #bf2d02;
}

.health-check-analysis table th,
.health-check-analysis table td {
  font-size: 0.9em !important;
}

.health-check-analysis table td.warning-color {
  color: rgb(242, 172, 15) !important;
  font-weight: bold;
}

.health-check-analysis table td.fail-color {
  color: rgb(254, 46, 46) !important;
  font-weight: bold;
}

.health-check-analysis table th {
  min-width: 115px;
  vertical-align: middle !important;
}

.health-check-analysis table td {
  overflow-wrap: anywhere !important;
}

.health-check-analysis table {
  border: 1px solid #e1e1e1;
}

.health-check-analysis ul li {
  font-size: 0.9em !important;
  margin: 7px 0 !important;
}

.client-quick-actions {
  padding: 0;
  background: #e9ebfa;
  margin-bottom: 0;
}

.center-checkbox {
  position: relative;
  top: 27px;
}

.tab-li:not(.active) {
  border-top: 1px solid #e9e9e9 !important;
}

.same-width {
  width: 250px;
  padding: 0 10px;
}

.same-width .title {
  font-size: 16px;
}

.save-and-close ul {
  padding: 0 !important;
  margin: 0 !important;
}

.save-and-close li {
  width: 100%;
}

.save-and-close-link {
  margin: 0 !important;
  padding: 4px 12px 1px 12px !important;
  font-size: 14px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 30px;
}

.save-and-close-link:hover {
  background: rgba(243, 243, 243, 0.658) !important;
  color: black !important;
}

.save-and-close-link i {
  margin-right: 8px;
  color: #0341b0;
}

.jarviswidget > header > h2 {
  font-size: 1.03em;
}

.btn i {
  margin-right: 5px;
}

.same-width:last-child {
  border-right-color: transparent;
}

.client-quick-actions .action-img {
  width: 30px;
  margin-right: 10px;
}

.custom-filter-class {
  -webkit-filter: invert(8%) sepia(100%) saturate(7414%) hue-rotate(359deg)
    brightness(94%) contrast(117%) !important;
  filter: invert(8%) sepia(100%) saturate(7414%) hue-rotate(359deg)
    brightness(94%) contrast(117%) !important;
}

.text-container {
  margin-right: auto;
  text-align: left;
}

.client-quick-actions .count {
  color: black;
  font-size: 27px;
  font-weight: bold;
}

.same-width h6 {
  margin: 0 0 1px 0;
  font-weight: 500;
  padding: 0;
  font-size: 15px;
}

.font-weight-bold {
  font-weight: bold;
}

.small-text-md h4 {
  font-weight: 500 !important;
}

.small-text-md .page-title {
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.page-title {
  font-size: 22px;
  font-weight: 750;
}

.nav-tabs > li > a {
  border-right: 1px solid #e2e2e2;
}

.percentage-icon {
  position: relative;
  font-size: 1.2em;
  right: 30px;
  background: #d4d4d4;
  color: #636161;
  padding: 4px 12px;
  border-radius: 5px;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
  border-top: 6px solid #adadad !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
  border-bottom: 6px solid #adadad !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
}

.ngx-toolbar .ngx-toolbar-set .ngx-editor-button {
  padding: 0.4rem !important;
  font-size: 1.1em !important;
}

.custom-loader-wrapper {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.custom-loader {
  border: 5px solid #e9e9e9;
  border-top: 5px solid #0181c1;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.custom-loader-text {
  font-size: 1.1em;
  margin-top: 10px;
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.dx-sb {
  position: relative;
  top: 5px;
  font-size: 1em !important;
}

.dx-sb .dx-placeholder {
  line-height: 5px;
  padding-bottom: 10px !important;
  font-size: 1em !important;
}

.dx-sb.dx-sb-multi .dx-placeholder {
  line-height: 17px !important;
}

.dx-sb .dx-texteditor-input {
  font-size: 1em !important;
}

.form-group.space-bottom {
  margin-bottom: 30px !important;
}

span.sp-sep {
  position: absolute;
  transform: rotate(90deg);
  letter-spacing: 3px;
  color: #ababab;
  left: 395px;
}

span.sp-sep.sp-sep-1 {
  top: 129px;
}

span.sp-sep.sp-sep-2 {
  top: 213px;
}

.icons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 25px;
  right: 8px;
}

.g-e-m {
  position: absolute;
  top: 0px;
  right: 0px;
  background: #b28529;
  color: white;
  border: none;
  outline: none;
  padding: 5.8px 10px;
  font-size: 1.1em;
  cursor: pointer !important;
  border-radius: 1px;
}

.g-e-m:disabled {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}

.g-e-m:hover {
  background: #9a711f;
}

.icons-wrapper i {
  font-size: 1.15em;
  padding: 7px;
  color: white;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.row.cs-row {
  padding-top: 25px;
  margin-bottom: 25px;
  box-shadow: rgb(60 64 67 / 20%) 0px 1px 2px 0px,
    rgb(60 64 67 / 15%) 0px 1px 3px 1px;
  border-radius: 3px;
}

.icons-wrapper i.i-save {
  background: #006099;
}

.icons-wrapper i.i-save:hover {
  background: #00507e;
}

.icons-wrapper i.i-delete {
  background: #af2517;
}

.icons-wrapper i.i-delete:hover {
  background: #941d0f;
}

span.sp-sep::before {
  content: "........";
}

.portal-section-title {
  color: #3a3a3a;
  margin-bottom: 25px;
  border-bottom: 1px solid #b3b3b3;
  border-bottom-style: dashed;
}

.d-flex-full-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

#firm-residual-risk-select-box .dx-texteditor-input {
  font-size: 1em !important;
  position: relative !important;
  bottom: 6px !important;
  padding-left: 32px !important;
  color: #202020 !important;
}

#firm-mitigation-measures-select-box .dx-placeholder {
  font-size: 1em !important;
}

#firm-mitigation-measures-select-box .dx-texteditor-input {
  font-size: 1em !important;
}

#firm-residual-risk-select-box .dx-placeholder {
  line-height: 5px !important;
  padding-bottom: 10px !important;
  font-size: 1em !important;
}

.bdg {
  width: 13px;
  height: 13px;
  position: absolute;
  border-radius: 50%;
  top: 10px;
  left: 9px;
}

@media only screen and (max-width: 1500px) {
  .small-text-md h4 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 479px) and (min-width: 320px) {
  .jarviswidget header h2 {
    width: 80%;
  }
}

/* Styles for displaying a smaller modal inside a pop out (e.g. evaluations wizard) */
@media (min-width: 992px) {
  .modal-md {
    width: 850px;
  }
}

.datetimeDevExtreme {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
}

/* Styles for making the border of an input element red (has error) and back to normal */
.input-no-error {
  border-color: #bdbdbd !important;
}

.input-with-error {
  border-color: #b94a48 !important;
}

.float-child-image {
  width: 30%;
  float: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.float-child-details {
  width: 70%;
  float: right;
}

.center-image {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.sa-button-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  right: 0;
}

.sa-button-left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  left: 0;
}

.dx-popup-content dx-data-grid {
  min-width: 525px;
}
a.dx-link.dx-icon-chevrondoubleright.dx-link-icon,
i.fa.fa-angle-double-right {
  font-size: 30px !important;
  width: 50px !important;
  display: inline-block !important;
  text-align: center !important;
}
